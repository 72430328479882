import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import useQS from '../hooks/useQS';
import { AuthContext } from '../contexts/AuthContext';

const Authenticated = () => {
  const { token } = useQS();
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    dispatch({
      type: 'LOGIN',
      payload: {
        token,
      },
    }).finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>{loading ? <p>Loading</p> : <Redirect to="/read" />}</div>;
};

export default Authenticated;
