import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useQuery } from 'react-query';
import fetchHomePage from '../data-sources/fetchHomePage';
import PostCard from '../components/PostCard';
import { AuthContext } from '../contexts/AuthContext';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

const Read = () => {
  const classes = useStyles();
  const [state, dispatch] = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const { status: loadingPosts, data: posts } = useQuery('posts', () =>
    fetchHomePage('hot', 10, '')
  );

  useEffect(() => {
    dispatch({ type: 'VALIDATE' }).finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        {loading || loadingPosts === 'loading' ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ minHeight: '100vh' }}
          >
            <Grid item xs={12}>
              <Typography variant="h5" align="center" color="textPrimary" paragraph>
                Hey, {state.name}.
              </Typography>
            </Grid>
            {posts.listing.map((post) => (
              <Grid item xs={12} key={post.id}>
                <PostCard post={post} />
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </div>
  );
};

export default Read;
