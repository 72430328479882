import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Index from './views/index';
import Authenticated from './views/authenticated';
import Read from './views/read';
import './App.css';

function App() {
  return (
    <Router>
      <div id="App" className="app">
        <CssBaseline />
        <Switch>
          <Route exact path="/" component={Index} />
          <Route exact path="/authenticated" component={Authenticated} />
          <Route exact path="/read" component={Read} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
