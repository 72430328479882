/* eslint-disable no-undef */
import React from 'react';
import axios from 'axios';
import useAsyncReducer from '../hooks/useAsyncReducer';

export const AuthContext = React.createContext();

async function authenticateUser(token) {
  const res = await axios.get('/.netlify/functions/validate', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (res.status !== 200) {
    return {
      success: false,
    };
  }
  return {
    success: true,
    name: res.data.name,
  };
}

const initialState = {
  isAuthenticated: false,
  name: null,
  token: null,
};

const reducer = (state, action) => {
  return new Promise((resolve) => {
    switch (action.type) {
      case 'LOGIN':
        authenticateUser(action.payload.token).then((result) => {
          if (result.success) {
            localStorage.setItem('name', result.name);
            localStorage.setItem('token', action.payload.token);
            resolve({
              ...state,
              isAuthenticated: true,
              name: result.name,
              token: action.payload.token,
            });
          } else {
            resolve({
              ...initialState,
            });
          }
        });
        break;
      case 'VALIDATE':
        authenticateUser(localStorage.getItem('token')).then((result) => {
          if (result.success) {
            resolve({
              ...state,
              isAuthenticated: true,
              name: result.name,
              token: localStorage.getItem('token'),
            });
          } else {
            localStorage.removeItem('token');
            localStorage.removeItem('name');
            resolve({
              ...initialState,
            });
          }
        });
        break;
      case 'LOGOUT':
        localStorage.clear();
        resolve({
          ...initialState,
        });
        break;
      default:
        resolve(state);
        break;
    }
  });
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useAsyncReducer(reducer, initialState);

  return <AuthContext.Provider value={[state, dispatch]}>{children}</AuthContext.Provider>;
};
