import axios from 'axios';

const fetchHomePage = async (type, limit, after) => {
  const token = localStorage.getItem('token');
  const payload = JSON.stringify({
    method: 'GET',
    url: `/${type}`,
    params: {
      limit,
      after,
      raw_json: 1,
      sr_detail: 1,
    },
  });

  const response = await axios({
    method: 'POST',
    url: '/.netlify/functions/getListing',
    headers: {
      Authorization: token,
    },
    data: payload,
    json: true,
  });

  const { data } = response;

  return data;
};

export default fetchHomePage;
